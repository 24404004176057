import { Injectable, inject } from '@angular/core';
import { DOPGroup } from '../model/crm/daily-operating-plan/dop-group';
import { AorBuilderService } from './aor-builder.service';
import { DOPLabel } from '../model/crm/daily-operating-plan/dop-label';
import { getCorrectedDate } from '../utils/utils';
import { DOPEntry } from '../model/crm/daily-operating-plan/dop-entry';
import { DOPAcknowledgement } from '../model/crm/daily-operating-plan/dop-acknowledegment';
import { ReportSapOutage } from '../model/reporting/report-sap-outage';
import {
  SapOutage,
  SapOutageCause,
  SapOutageStatus,
} from '../model/sap/sap-outage';
import { FacilityBuilderService } from './facility-builder.service';
import { SapOutageTask } from '../model/sap/sap-outage-task';
import { OutageGroup, OutageGroupCompanyCode } from '../model/sap/outage-group';

@Injectable({
  providedIn: 'root',
})
export class SapBuilderService {
  protected facilityBuilder = inject(FacilityBuilderService);


  buildReportSapOutage(item: any): ReportSapOutage {
    return {
      id: item.id,
      notifNum: item.notifNum,
      flocNumber: item.flocNumber,
      reqStart: item.reqStart,
      reqEnd: item.reqEnd,
      funcLocDesc: item.funcLocDesc,
      shortDesc: item.shortDesc,
      taskText: item.taskText,
      longText: item.longText ?? item.longDesc,
    };
  }

  buildSapOutage(item: any): SapOutage {
    return {
      id: item.id,
      facilityId: item.facilityId,
      flocNumber: item.flocNumber,
      notifNum: item.notifNum,
      notifDate: item.notifDate,
      notifCreatedDate: item.notifCreatedDate,
      notifChangedDate: item.notifChangedDate,
      notifType: item.notifType,
      shortDesc: item.shortDesc,
      longDesc: item.longDesc,
      workCenter: item.workCenter,
      companyCode: item.companyCode,
      funcLoc: item.funcLoc,
      funcLocDesc: item.funcLocDesc,
      funcLocObjType: item.funcLocObjType,
      funcLocSort: item.funcLocSort,
      codingCatType: item.codingCatType,
      codingCodeGrp: item.codingCodeGrp,
      codingCode: item.codingCode,
      reqStart: item.reqStart,
      reqEnd: item.reqEnd,
      Facility: item.Facility
        ? this.facilityBuilder.buildFacility(item.Facility, [])
        : null,
      Tasks: item.Tasks
        ? item.Tasks.map((t) => this.buildSapOutageTask(t))
        : null,
      Statuses: item.Statuses
        ? item.Statuses.map((t) => this.buildSapOutageStatus(t))
        : null,
      Causes: item.Causes
        ? item.Causes.map((t) => this.buildSapOutageCause(t))
        : null,
    };
  }

  buildSapOutageTask(item: any): SapOutageTask {
    return {
      id: item.id,
      sapOutageId: item.sapOutageId,
      taskNumber: item.taskNumber,
      sortNumber: item.sortNumber,
      taskCode: item.taskCode,
      codeText: item.codeText,
      taskText: item.taskText,
      longText: item.longText,
      statusCode: item.statusCode,
      statusText: item.statusText,
    };
  }

  buildSapOutageStatus(item: any): SapOutageStatus {
    return {
      id: item.id,
      sapOutageId: item.sapOutageId,
      codeType: item.codeType,
      code: item.code,
      text: item.text,
    };
  }

  buildSapOutageCause(item: any): SapOutageCause {
    return {
      id: item.id,
      sapOutageId: item.sapOutageId,
      itemNum: item.itemNum,
      issueText: item.issueText,
      causeNum: item.causeNum,
      causeCatalogType: item.causeCatalogType,
      causeCodeGroup: item.causeCodeGroup,
      causeCode: item.causeCode,
      causeCodeText: item.causeCodeText,
      causeText: item.causeText,
    };
  }

  buildOutageGroup(item: any): OutageGroup {
    return {
      id: item.id,
      name: item.name,
      enabled: item.enabled,
      CompanyCodes: item.CompanyCodes
        ? item.CompanyCodes.map((i) => this.buildOutageGroupCompanyCode(i))
        : null,
    };
  }

  buildOutageGroupCompanyCode(item: any): OutageGroupCompanyCode {
    return {
      id: item.id,
      outageGroupId: item.outageGroupId,
      companyCode: item.companyCode,
    };
  }
}
