import { Injectable, inject } from '@angular/core';
import { BaseAppSyncService } from './base-app-sync.service';
import { AppsyncService } from './appsync.service';
import { Observable, from } from 'rxjs';
import { WatchQueryOptions } from 'apollo-client';
import { TC_CLAIMS_HEADER } from '../constants/auth-constants';
import { AuthApiService } from './auth-api.service';
import { map, switchMap } from 'rxjs/operators';
import { getOpenOutages } from '../graphql/sap/get-open-outages';
import { getSapOutage } from '../graphql/sap/get-sap-outage';
import { syncSapOutagesForFacility } from '../graphql/facilities/sync-sap-outages-for-facility';
import { SapOutage } from '../model/sap/sap-outage';
import { SapBuilderService } from './sap-builder.service';
import { OutageGroup } from '../model/sap/outage-group';
import { getOutageGroups } from '../graphql/sap/get-outage-groups';

@Injectable({
  providedIn: 'root',
})
export class SapApiService extends BaseAppSyncService {
  protected _api: AppsyncService;
  protected _authApi = inject(AuthApiService);
  protected _sapBuilderService = inject(SapBuilderService);

  constructor() {
    const _api = inject(AppsyncService);

    super(_api);
    this._api = _api;

  }

  getOpenOutages(): Observable<any> {
    const options: WatchQueryOptions = {
      query: getOpenOutages,
      context: {
        headers: {
          [TC_CLAIMS_HEADER]: this._authApi.getUserClaimsHeader(),
        },
      },
      fetchPolicy: 'no-cache',
    };
    return from(this._api.hc2()).pipe(
      switchMap((client) => {
        return client.query(options);
      })
    );
  }

  syncSapOutagesForFacility(floc: string): Observable<boolean> {
    return from(this._api.hc2())
      .pipe(
        switchMap((client: any) => {
          return client.mutate({
            mutation: syncSapOutagesForFacility,
            variables: { floc },
            context: {
              headers: {
                [TC_CLAIMS_HEADER]: this._authApi.getUserClaimsHeader(),
              },
            },
          });
        })
      )
      .pipe(
        map((data: any) => {
          return data.data.syncSapOutagesForFacility;
        })
      );
  }

  getSapOutage(outageId: string): Observable<SapOutage> {
    return this.query(
      getSapOutage,
      { outageId },
      (item: any) => this._sapBuilderService.buildSapOutage(item),
      'getSapOutage'
    );
  }

  getOutageGroups(): Observable<OutageGroup[]> {
    return this.query(
      getOutageGroups,
      {},
      (i: any) => i.map((i) => this._sapBuilderService.buildOutageGroup(i)),
      'getOutageGroups'
    );
  }
}
